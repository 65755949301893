import { useState } from 'react';

import { Card } from '../../components/comp-dashboards/data-cards/Card';
import { Menu } from '../../components/comp-dashboards/menu/Menu';
import { ReactDashboard } from '../../components/comp-dashboards/react-dashboards/ReactDashboard';

import { menu_list } from './const';

export const Dashboard = (props) => {
  const [selectedIdDashboardReact, setSelectedIdDashboardReact] =
    useState(null);

  return (
    <>
      <div style={{ width: '95vw', marginLeft: '2.5vw' }}>
        <div className="row mt-3">
          {/* VENDA */}
          <div className="col-sm-12 col-md-12 col-lg-4 mb-1">
            <Card
              id={
                localStorage.getItem('sessionToken')
                  ? '#MenuDashboard1List'
                  : '#ModalError'
              }
              title="Dashboards"
              icon="fa-solid fa-chart-line"
              bgDashboard={props.bgDashboard1}
              fontDashboard={props.fontDashboard1}
              tamTexto={props.tamTexto}
              tamSubTexto={props.tamSubTexto}
            />
            <Menu
              title="Dashboards"
              id="MenuDashboard1List"
              modalId="#MenuDashboard"
              lista={menu_list || []}
              tamTexto={props.tamTexto}
              setSelectedIdDashboardReact={setSelectedIdDashboardReact}
            />
            <ReactDashboard
              id="MenuDashboard"
              title="Dashboards"
              tamTitulo={props.tamTitulo}
              selectedIdDashboardReact={selectedIdDashboardReact}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="msgError"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className="alert alert-danger alert-dismissible"
                role="alert"
              >
                <div style={{ fontSize: props.tamTexto }}>Não Logado</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
