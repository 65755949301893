import ReactSelect from 'react-select';

export const FiltroDropdown = ({
  listaItems,
  setValue,
  title,
  isMulti = false
}) => {
  const handleChange = (event) => {
    setValue(event);
  };

  const options = listaItems?.map((item) => {
    return { value: item.value, label: item.item, codigo: item.codigo };
  });

  return (
    <div
      className="d-flex flex-column w-100 p-1 rounded"
      style={{ backgroundColor: '#fff' }}
    >
      <label className="form-label m-0">{title}</label>
      <ReactSelect
        closeMenuOnSelect={false}
        isMulti={isMulti}
        options={options}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            margin: 0,
            overflowY: 'auto',
            maxHeight: '65px'
          })
        }}
        onChange={handleChange}
      />
    </div>
  );
};
