// const Line = (props) => {
//   return (
//     <p className="card-category d-flex justify-content-between">
//       <span
//         style={{
//           fontSize: props.tamSubTexto,
//           color: props.label ? 'inherit' : 'transparent'
//         }}
//       >
//         {props.label || '.'}
//       </span>
//       <span style={{ fontSize: props.tamSubTexto }}>{props.value}</span>
//     </p>
//   );
// };

export const Card = (props) => {
  return (
    <div
      className="card cursor"
      data-bs-toggle="modal"
      data-bs-target={props.id}
      style={{
        backgroundColor: props.bgDashboard,
        color: props.fontDashboard
      }}
    >
      <div className="content">
        <div className="row">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h6 style={{ fontSize: props.tamTexto }}>{props.title}</h6>
            <i className={`${props.icon} icon`}></i>
          </div>

          {/* <div className="col-9">
            <div className="text-end mt-2 me-2">
              <Line
                tamSubTexto={props.tamSubTexto}
                label={props.data?.label1}
                value={props.data?.value1}
              />
              <Line
                tamSubTexto={props.tamSubTexto}
                label={props.data?.label2}
                value={props.data?.value2}
              />
              <Line
                tamSubTexto={props.tamSubTexto}
                label={props.data?.label3}
                value={props.data?.value3}
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
