import { useState, useEffect } from 'react';

import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from '@tanstack/react-table';

function getMonthName(monthIndex) {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ];
  return months[monthIndex - 1] || '';
}

function formatData(data, listColumns) {
  const primaryColumn = listColumns[0].id;

  const uniqueData = data.filter(
    (item, index) =>
      index === 0 || item[primaryColumn] !== data[index - 1][primaryColumn]
  );

  return uniqueData.map((item) => {
    const [year, month] = item[primaryColumn].split('-');
    const formattedMonth = getMonthName(parseInt(month, 10));

    return {
      [primaryColumn]: `${year}, ${formattedMonth}`,
      [listColumns[1].id]: '',
      [listColumns[2].id]: '',
      [listColumns[3].id]: '',
      [listColumns[4].id]: '',
      [listColumns[5].id]: '',
      subRows: []
    };
  });
}

function formatChildrenData(dataInitial, formattedData, listColumns) {
  const primaryColumn = listColumns[0].id;

  const completeData = formattedData.map((item) => ({ ...item, subRows: [] }));

  dataInitial.forEach((detailItem) => {
    const [year, month] = detailItem[primaryColumn].split('-');
    const formattedMonthYear = `${year}, ${getMonthName(parseInt(month, 10))}`;

    completeData.forEach((parentItem) => {
      if (parentItem[primaryColumn] === formattedMonthYear) {
        parentItem.subRows.push({
          [listColumns[1].id]: detailItem[listColumns[1].id],
          [listColumns[2].id]: detailItem[listColumns[2].id],
          [listColumns[3].id]: detailItem[listColumns[3].id],
          [listColumns[4].id]: detailItem[listColumns[4].id],
          [listColumns[5].id]: detailItem[listColumns[5].id]
        });
      }
    });
  });

  return completeData;
}

export const TabelaExpandeHora = ({ dataInitial, listColumns, titulo }) => {
  const [expanded, setExpanded] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    const formattedData = formatData(dataInitial, listColumns);
    const completeData = formatChildrenData(
      dataInitial,
      formattedData,
      listColumns
    );
    setData(completeData);
  }, [dataInitial, listColumns]);

  const columns = [
    {
      id: 'mes_ano',
      header: 'Mês e Ano',
      accessorKey: 'mes_ano',
      cell: ({ row, getValue }) => (
        <div style={{ paddingLeft: `${row.depth * 2}rem` }}>
          {row.getCanExpand() && (
            <button
              className="btn btn-sm"
              onClick={row.getToggleExpandedHandler()}
              style={{ cursor: 'pointer' }}
            >
              <i
                className="fa-solid fa-caret-down"
                style={{
                  transform: row.getIsExpanded()
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)'
                }}
              ></i>
            </button>
          )}
          {getValue()}
        </div>
      ),
      footer: () => 'Mês e Ano'
    },
    {
      id: 'dia',
      header: 'Dia',
      accessorKey: 'dia',
      cell: ({ getValue }) => <div>{getValue()}</div>,
      footer: () => 'Dia'
    },
    {
      id: 'ocr_codoco',
      header: 'Código OCR',
      accessorKey: 'ocr_codoco',
      cell: ({ getValue }) => <div>{getValue()}</div>,
      footer: () => 'Código OCR'
    },
    {
      id: 'hora',
      header: 'Hora',
      accessorKey: 'hora',
      cell: ({ getValue }) => <div>{getValue()}</div>,
      footer: () => 'Hora'
    },
    {
      id: 'ocr_tipident1',
      header: 'Tipo Identificador',
      accessorKey: 'ocr_tipident1',
      cell: ({ getValue }) => <div>{getValue()}</div>,
      footer: () => 'Tipo Identificador'
    },
    {
      id: 'ocr_conclusao',
      header: 'Conclusão',
      accessorKey: 'ocr_conclusao',
      cell: ({ getValue }) => <div>{getValue()}</div>,
      footer: () => 'Conclusão'
    }
  ];

  const table = useReactTable({
    data,
    columns,
    state: { expanded },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  });

  return (
    <div className="p-2" style={{ width: '95%', height: '72vh' }}>
      <div className="text-center">
        <h4 className="text">{titulo}</h4>
      </div>
      <div style={{ height: 'calc(100% - 60px)', overflowY: 'auto' }}>
        <table className="table table-striped table-hover">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className="table-secondary" key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
