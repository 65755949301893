import { useEffect, useState } from 'react';

import { formatRequestArray } from '../../../functions/formatRequestArray';
import { ApiGet } from '../../../services/apiService';
import { TabelaExpandeDia } from '../graficos/TabelaExpandeDia';
import { TabelaExpandeHora } from '../graficos/TabelaExpandeHora';
import { TreeMap } from '../graficos/Treemap';
import { FiltroDropdown } from './FiltroDropdown';

export const OcorrenciaDashboard = () => {
  const [filterAno, setFilterAno] = useState([]);
  const [filterMes, setFilterMes] = useState([]);
  const [filterCod, setFilterCod] = useState([]);
  const [options, setOptions] = useState({ ano: [], mes: [], cod: [] });
  const [dataOcrDia, setDataOcrDia] = useState([]);
  const [dataOcrHora, setDataOcrHora] = useState([]);
  const [dataOcrQtd, setDataOcrQtd] = useState([]);

  const handleOptions = () => {
    const currentYear = new Date().getFullYear();

    const anos = Array.from({ length: 6 }, (_, i) => {
      const year = currentYear - i;
      return {
        item: year,
        value: year,
        codigo: year
      };
    });

    const monthNames = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ];
    const meses = monthNames.map((name, index) => ({
      item: name,
      value: index + 1,
      codigo: index + 1
    }));

    const requests = [
      ApiGet(
        '/v1/selectTabela',
        {
          select: ['ocr_codoco'],
          from: 'ocorrencias',
          groupBy: 'ocr_codoco',
          order: { column: 'ocr_codoco' }
        },
        localStorage.getItem('sessionToken')
      )
    ];

    Promise.all(requests)
      .then((responses) => {
        let optionsCod = responses[0].data.dados.rows.map((item) => {
          return {
            item: item.ocr_codoco,
            value: item.ocr_codoco,
            codigo: item.ocr_codoco
          };
        });

        setOptions({ ano: anos, mes: meses, cod: optionsCod });
      })
      .catch((error) => {
        console.error('Error ao recuperar dados para filtros', error);
      });
  };

  const handleDataCharts = () => {
    const requests = [
      ApiGet(
        'v1/graficos/suporte/ocorrencias/dia',
        {
          ano: formatRequestArray(filterAno),
          mes: formatRequestArray(filterMes),
          cod: formatRequestArray(filterCod)
        },
        localStorage.getItem('sessionToken')
      ),
      ApiGet(
        'v1/graficos/suporte/ocorrencias/hora',
        {
          ano: formatRequestArray(filterAno),
          mes: formatRequestArray(filterMes),
          cod: formatRequestArray(filterCod)
        },
        localStorage.getItem('sessionToken')
      ),
      ApiGet(
        'v1/graficos/suporte/ocorrencias/quantidade',
        {
          ano: formatRequestArray(filterAno),
          mes: formatRequestArray(filterMes),
          cod: formatRequestArray(filterCod)
        },
        localStorage.getItem('sessionToken')
      )
    ];

    Promise.all(requests)
      .then((responses) => {
        const dados = responses[0].data.dados.map((item) => {
          return item;
        });
        setDataOcrDia(dados);

        const dados1 = responses[1].data.dados.map((item) => {
          return item;
        });
        setDataOcrHora(dados1);

        const dados2 = responses[2].data.dados.map((item) => {
          return item;
        });
        setDataOcrQtd(dados2);
      })
      .catch((error) => {
        console.error('Error ao recuperar dados para os gráficos', error);
      });
  };

  useEffect(() => {
    handleOptions();
  }, []);

  useEffect(() => {
    handleDataCharts();
  }, [filterAno, filterMes, filterCod]);

  return (
    <div className="w-100" style={{ backgroundColor: '#eeeeee' }}>
      <h4>Dashboard Ocorrências</h4>

      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-2 mb-2 p-1">
        <FiltroDropdown
          title="Ano de Ocr Datatz"
          listaItems={options.ano}
          setValue={setFilterAno}
          isMulti={true}
        />
        <FiltroDropdown
          title="Mês de Ocr Datatz"
          listaItems={options.mes}
          setValue={setFilterMes}
          isMulti={true}
        />
        <FiltroDropdown
          title="C. Ocor"
          listaItems={options.cod}
          setValue={setFilterCod}
          isMulti={true}
        />
      </div>

      <div className="w-100 h-100">
        <div className="d-flex flex-column flex-lg-row justify-content-center w-100 h-100">
          <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100 gap-3">
            {dataOcrDia.length > 0 && (
              <TabelaExpandeDia
                listColumns={[
                  { id: 'ano', header: 'Ano de Ocr' },
                  { id: 'mes', header: 'Mês de Ocr' },
                  { id: 'dia', header: 'Dia de Ocr' },
                  { id: 'ocr_codoco', header: 'C. Ocor' },
                  { id: 'quantidade', header: '' }
                ]}
                dataInitial={dataOcrDia}
                titulo={'Ocorrência por Dia'}
              />
            )}
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
            <TreeMap data={dataOcrQtd} title="Ocorrência por Cod" />

            {dataOcrDia.length > 0 && (
              <TabelaExpandeHora
                listColumns={[
                  { id: 'mes_ano', header: 'Mês de Ocr' },
                  { id: 'dia', header: 'Dia de Ocr' },
                  { id: 'hora', header: 'Hora de Ocr' },
                  { id: 'ocr_codoco', header: 'C. Ocor' },
                  { id: 'ocr_tipident1', header: 'Reserva' },
                  { id: 'ocr_conclusao', header: 'Ocr Conclusão' }
                ]}
                dataInitial={dataOcrHora}
                titulo={'Ocorrência por Resev Hora'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
