import { ResponsiveTreeMap } from '@nivo/treemap';

export const TreeMap = ({ data }) => {
  const data_completo = {
    name: 'Total',
    children: data.map((item) => ({
      name: item.ocr_codoco,
      value: item.quantidade
    }))
  };

  return (
    <div style={{ height: '400px', width: '95%' }}>
      <h4 style={{ backgroundColor: 'gray', color: 'white' }}>
        Total do Saldo por Período
      </h4>
      <ResponsiveTreeMap
        data={data_completo}
        identity="name"
        value="value"
        label={(node) => `${node.data.name}: ${node.value}`}
        labelSkipSize={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.2]]
        }}
        colors={{ scheme: 'blues' }}
        colorBy="value"
        borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
      />
    </div>
  );
};
